@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Space+Grotesk:wght@300;400;500;700&display=swap");
@import url('https://fonts.cdnfonts.com/css/gilroy-bold');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap');
@import "~leaflet/dist/leaflet.css";
 @import "~react-leaflet-markercluster/dist/styles.min.css";
/* Roots */
:root{
  --primary:#4F008A;
  --primaryI:#B2C8FF;
  --black:#060D1C;
  --blackI:#282728;
  --blackII:#444444;
  --blackIII:#555555;
  --blackIV:#333333;
  --danger:#D63434;
 --dangerI:rgba(214, 52, 52, 0.1);
 --dangerII:#DF6464;
 --gray: rgba(79, 0, 138, 0.05);
 --grayI:#A1A1A1;
 --grayII:rgba(0, 0, 0, 0.1);
 --grayIII:#656468;
 --green:rgba(4, 78, 84, 0.1);
 --success:#3DA84A;
}

body{
  margin: 0;
  /* font-family: "Poppins", sans-serif !important; */
  font-family: 'Satoshi', sans-serif !important;

}
.check{
  scrollbar-width: none !important;
}
a{
  text-decoration: none !important;
}
*{
  box-shadow: none !important;
  outline: none !important;
}

h1,h2,h3,h4,h5,h6{
  font-family: "Space Grotesk", sans-serif !important;

}

.font-1{
  
  font-family: 'Gilroy-Regular', sans-serif !important;
}
.font-2{
  
 
  font-family: 'Gilroy-Medium', sans-serif !important;}
.font-3{
  
  font-family: 'Gilroy-Bold', sans-serif !important;
 

}
.font-4{
  
  font-family: 'Gilroy-Heavy', sans-serif !important;}
.font-5{
  font-family: 'Satoshi', sans-serif !important;

}
.font-6{
  font-family: 'Inter', sans-serif !important;
}
.h14{
  font-size: 14px;
}
.h18{
  font-size: 18px;
}

.primaryI{
  color: var(--primary);
}

.blackII{
  color: var(--blackII);
}
.blackIII{
  color: var(--blackIII);
}
.blackI{
  color: var(--blackI);
}
.blackIV{
  color: var(--blackIV);
}
.grayIII{
  color: var(--grayIII);
}
.success{
  color: var(--success);
}

.bg-primaryI{
  background-color: var(--primary) !important;
}
.bg-primaryII{
  background-color: var(--primaryI);
}

.bg-gray{
  background-color: var(--gray);
}
.pointer{
  cursor: pointer;
}

button{
  background-color: transparent;
}
.dropdown-toggle::after{
  display: none !important;
}

/* media query */
@media only screen and (max-width: 600px) {
  
}

.spin {
  animation-name: spin;
  animation-duration: 2s;
  animation-iteration-count: 3;
  animation-fill-mode: forwards;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}


.markercluster-map {
  height: 90vh;
}

.dropdown-item:focus, .dropdown-item.active {
  background-color: #4F008A !important;
}

.offcanvas, .offcanvas-lg, .offcanvas-md, .offcanvas-sm, .offcanvas-xl, .offcanvas-xxl {
  --bs-offcanvas-width: 600px !important;
  
}

.new{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

.side{
  scrollbar-width: none !important;
}